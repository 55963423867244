<template>
  <component
    :is="header"
    :authorized="authStore.isAuthorized"
    :show-links="showLinks"
    :extension-url="extensionUrl"
    :blog-url="blogUrl"
    :hide-submit-ext-btn="hideSubmitExtBtn"
    :is-header-grey="isHeaderGrey"
    :is-home="isHome"
    :is-tax-return-preparation-active="isTaxReturnPreparationActive"
    :tax-return-preparation-link="taxReturnPreparationLink"
    :is-registration="isRegistration" />
</template>
<script lang="ts">
import "@/styles/pages/_landing.scss";

import { defineComponent } from "vue";

import HeaderEfileTaxNet from "@/components/header/efiletaxnet-header.vue";
import HeaderFileDirectNet from "@/components/header/filedirectnet-header.vue";
import HeaderFileSmartTax from "@/components/header/filesmarttax-header.vue";
import { useAuthStore } from "@/store/auth.store";
import type { ShowHeaderMenu } from "@/store/types";
import { getSiteBrandFromConfig } from "@/util/helpers";
import { FLOW, ROUTE_NAMES } from "@/util/routes/route-names";

export default defineComponent({
  name: "AppHeader",
  components: {
    "header-efiletaxnet": HeaderEfileTaxNet,
    "header-filesmarttax": HeaderFileSmartTax,
    "header-filedirectnet": HeaderFileDirectNet
  },
  setup() {
    const authStore = useAuthStore();

    return { authStore };
  },
  computed: {
    showLinks(): boolean {
      const showLinksMap: Record<ShowHeaderMenu, boolean> = {
        always: true,
        never: false,
        "not-logged": !this.authStore.isAuthorized
      };

      const showHeaderMenu = (this.$route.meta?.showHeaderMenu ?? "always") as ShowHeaderMenu;

      return showLinksMap[showHeaderMenu];
    },
    header(): string {
      const brand = getSiteBrandFromConfig();

      switch (brand.toLowerCase()) {
        case "filesmart.tax":
          return "header-filesmarttax";
        case "filedirect.net":
          return "header-filedirectnet";
        default:
          return "header-efiletaxnet";
      }
    },
    extensionUrl(): string {
      if (this.authStore.isAuthorized) {
        return `/dashboard`;
      } else {
        return "/";
      }
    },
    blogUrl(): string | undefined {
      return import.meta.env.VITE_BLOG_URL;
    },
    hideSubmitExtBtn(): boolean {
      return this.$route.name !== "extension";
    },
    isBasicPage(): boolean {
      const requiresAuth = this.$route.meta?.requiresAuth;

      return !requiresAuth;
    },
    isHeaderGrey(): boolean {
      return this.authStore.isAuthorized && !this.isBasicPage;
    },
    isHome(): boolean {
      return this.$route.name === ROUTE_NAMES.landing;
    },
    isTaxReturnPreparationActive(): boolean {
      return ([FLOW.dfyReturn, FLOW.diyReturn] as string[]).includes(this.$route.meta.flow as string);
    },
    taxReturnPreparationLink(): string {
      return (this.$route.meta.flow as string) ?? FLOW.dfyReturn;
    },
    isRegistration(): boolean {
      return this.$route.name === ROUTE_NAMES.registration;
    }
  }
});
</script>
