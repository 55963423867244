import "trickling/lib/style.css";
import "@/plugins/onesignal";
import "@/plugins/trickling";

import { createBootstrap } from "bootstrap-vue-next";
import { createPinia } from "pinia";
import { createApp } from "vue";
import { plugin as InputFacade } from "vue-input-facade";
import VueScrollTo from "vue-scrollto";

import App from "@/app.vue";
import { initPosthog } from "@/plugins/posthog";
import { initSentry } from "@/plugins/sentry";
import { router } from "@/router";
import { initWatchSession } from "@/session";

const pinia = createPinia();
const app = createApp(App);

app.use(createBootstrap());
app.use(pinia);
app.use(router);

initSentry(app, router);

app.use(InputFacade);
app.use(VueScrollTo, { duration: 500, easing: "ease" });

app.mount("#app");

initPosthog(app);
initWatchSession(router);

export { app };
