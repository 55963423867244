<template>
  <footer class="app-footer app-footer-efiletaxnet" :class="{ 'app-footer-dark': darkFooter }">
    <div class="app-footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 d-none d-lg-inline">
            <div class="app-footer-logo">
              <router-link to="/" exact>
                <logo variant="white" class="white-logo" />
                <logo class="dark-logo" />
              </router-link>
            </div>
          </div>
          <div class="col-12 col-lg-6 justify-content-center justify-content-lg-end d-flex align-items-start">
            <div class="app-footer-logos">
              <inline-image
                :width="60"
                :height="60"
                name="ssl-t"
                :formats="['webp', 'png']"
                alt="100% Secure SSL Transaction"
                :sizes="4" />
              <inline-image
                :width="74"
                :height="53"
                name="bbb"
                :formats="['webp', 'png']"
                alt="BBB Accredited Business"
                :sizes="4" />
              <inline-image
                :width="46"
                :height="45"
                name="irs"
                :formats="['webp', 'png']"
                alt="Authorized IRS provider"
                :sizes="4" />
            </div>
          </div>
        </div>

        <div class="row mt-lg-0 mt-4 align-items-center">
          <div class="col-12 col-lg-6">
            <div class="app-footer-disclaimer">
              <div>IMPORTANT DISCLAIMERS:</div>
              <div>
                {{ hostname }} is designated as an Authorized IRS e-file Provider. An Authorized IRS e-file Provider
                (Provider) is a business or organization authorized by the IRS to participate in the IRS e-file program.
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mt-4 mb-2">
            <ul class="app-footer-links list-unstyled">
              <li>
                <router-link to="/">Home</router-link>
              </li>
              <li>
                <router-link to="/blog">Blog</router-link>
              </li>
              <li v-if="!authorized">
                <router-link to="/login">Customer Login</router-link>
              </li>
              <li>
                <router-link to="/contact-us">Contact Us</router-link>
              </li>
              <li v-if="!authorized">
                <router-link to="/registration">File Tax Extension</router-link>
              </li>
              <li v-if="authorized">
                <router-link to="/extension">File Tax Extension</router-link>
              </li>
              <li v-if="authorized">
                <router-link to="/dashboard">Extension Status</router-link>
              </li>
            </ul>
            <ul class="app-footer-links list-unstyled">
              <li>
                <router-link to="/faq">FAQ</router-link>
              </li>
              <li>
                <a href="/terms-of-use">Terms of Use</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/refund-policy">Refund Policy</a>
              </li>
              <li>
                <b-link href="https://filesmart.hiringplatform.com/list/careers" target="_blank">Careers</b-link>
              </li>
              <li v-if="authorized">
                <router-link to="/logout">Logout</router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="app-footer-copyright">
              <div>&copy; {{ hostname }}. All right reserved {{ year }} {{ version }}.</div>
            </div>
            <div v-if="sessionInfo !== ''" class="app-footer-funnel">
              <div>{{ sessionInfo }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

import InlineImage from "@/components/inline-image.vue";
import logo from "@/components/logo/index.vue";
import { currentYear } from "@/config/constants";
import { useAuthStore } from "@/store/auth.store";
import { useAppStateStore } from "@/store/state.store";
import { addLeadEventToGTM } from "@/util/tracking";

export default defineComponent({
  name: "FooterEfiletaxnet",
  components: {
    "inline-image": InlineImage,
    logo
  },
  props: {
    authorized: {
      type: Boolean,
      default: false
    },
    offer: {
      type: String as PropType<string | undefined>,
      required: false
    }
  },
  setup() {
    const authStore = useAuthStore();
    const appStateStore = useAppStateStore();

    return { authStore, appStateStore };
  },
  data() {
    return {
      year: currentYear,
      hostname: window.location.hostname,
      version: import.meta.env.VITE_VERSION
    };
  },
  computed: {
    isBasicPage(): boolean {
      const requiresAuth = this.$route.meta?.requiresAuth;

      return !requiresAuth;
    },
    darkFooter(): boolean {
      return this.isBasicPage ?? this.authorized;
    },
    extensionUrl(): string {
      if (this.authorized) {
        return `/extension/1?${this.authStore.registrationSource}`;
      } else {
        return "/registration";
      }
    },
    sessionInfo(): string {
      const currentOffer = this.offer ? ` / ${this.offer}` : "";
      const funnel = this.authStore.funnel;

      if (this.authStore.userData?.email && this.authStore.userData.email !== "") {
        return `Funnel: ${this.appStateStore.settings.funnel ?? funnel.toUpperCase()}${currentOffer}`;
      }

      return `Funnel: ${funnel.toUpperCase()}`;
    }
  },
  methods: {
    addLeadEventToGTM
  }
});
</script>
