import type { PostHog } from "posthog-js";
import posthog from "posthog-js";
import type { App } from "vue";

import { isPosthogEnabled } from "@/config/constants";
import { usePostHogStore } from "@/store/posthog.store";

export const initPosthog = (app: App) =>
  app.use({
    install(_options) {
      if (!isPosthogEnabled()) {
        return;
      }

      posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
        api_host: import.meta.env.VITE_POSTHOG_HOST,
        loaded: onPostHogLoaded
      });
    }
  });

const onPostHogLoaded = (phInstance: PostHog) => {
  const searchParams = new URLSearchParams(window.location.search);

  // If the ingest_id is present, identify with the ingest_id
  if (searchParams.has("ingest_id")) {
    phInstance.identify(searchParams.get("ingest_id") ?? undefined);
  }

  const postHogStore = usePostHogStore();
  postHogStore.initialize(phInstance);
};
