import type { ComputedRef } from "vue";
import { computed } from "vue";

import { taxYear } from "@/config/constants";
import type { BackendAppControllerBoirForm } from "@/services/api";
import { useFormsStore } from "@/store/forms.store";
import type { Filing } from "@/store/types";
import { addLeadEventToGTM } from "@/util/tracking";

import { useEventBus } from "../use-event-bus";

interface UseFilingsReturn {
  getFilingsByExactYear(year: number): Filing[];
  thisYearFilings: ComputedRef<Filing[]>;
  getBoirFilingsByExactYear(year: number): BackendAppControllerBoirForm[];
  thisYearBoirFilings: ComputedRef<BackendAppControllerBoirForm[]>;
  openNewFilingModal(param?: { exclude?: string; usePlannerEvents?: boolean }): void;
  openOfferFilingsModal(defaultCardIsBroken?: boolean, preventAddToCart?: boolean): void;
  closeOfferFilingsModal(): void;
}

export const useFilings = (): UseFilingsReturn => {
  const formsStore = useFormsStore();
  const eventBus = useEventBus();

  const getFilingsByExactYear = (year: number): Filing[] => {
    return formsStore.getFilingsByYear(year);
  };

  const thisYearFilings = computed((): Filing[] => {
    return formsStore.getFilingsByYear(taxYear);
  });

  const getBoirFilingsByExactYear = (year: number): BackendAppControllerBoirForm[] => {
    return formsStore.getBoirFilingsByYear(year);
  };

  const thisYearBoirFilings = computed((): BackendAppControllerBoirForm[] => {
    return formsStore.getBoirFilingsByYear(taxYear);
  });

  const openNewFilingModal = (param?: { exclude?: string; usePlannerEvents?: boolean }): void => {
    eventBus.$emit("setShowNewFilingModal", { to: true, ...param });
    addLeadEventToGTM(7);
  };

  const openOfferFilingsModal = (): void => {
    eventBus.$emit("setShowOfferFilingsModal", { to: true });
  };

  const closeOfferFilingsModal = (): void => {
    eventBus.$emit("setShowOfferFilingsModal", { to: false });
  };

  return {
    getFilingsByExactYear,
    thisYearFilings,
    openNewFilingModal,
    openOfferFilingsModal,
    closeOfferFilingsModal,
    getBoirFilingsByExactYear,
    thisYearBoirFilings
  };
};
